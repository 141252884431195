* {
  box-sizing: border-box;
}

body {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  max-height: -webkit-fill-available;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  background-color: #644a77;
  background-image: url("castle_bg.7dee3fac.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

@keyframes mibounce {
  0%, 100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-15px);
  }
}

@keyframes mifloat {
  0% {
    transform: translateY(50%);
  }

  25% {
    transform: translateY(55%);
  }

  100% {
    transform: translateY(50%);
  }
}

.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.crepi-wrapper {
  max-width: 100%;
  text-align: center;
  z-index: 10;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.crepi-wrapper .bubble {
  opacity: 1;
  z-index: 10;
  transition: opacity 2s ease-in-out;
}

.crepi-wrapper .bubble .messages.hidden, .crepi-wrapper .bubble .clicking-game.hidden {
  display: none;
}

.crepi-wrapper .bubble .close-btn {
  cursor: pointer;
}

.crepi-wrapper img.crepi {
  cursor: pointer;
  max-width: 70vw;
  max-height: 50vh;
  filter: drop-shadow(15px 15px 15px #444);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  z-index: 5;
  transition: translateY 2s ease-in-out;
  position: relative;
}

.crepi-wrapper.hidden .bubble {
  opacity: 0;
}

.crepi-wrapper.hidden img.crepi {
  animation: 6s ease-in-out infinite mifloat;
}

.crepi-wrapper:not(.hidden) img.crepi:hover, .crepi-wrapper:not(.hidden) img.crepi:focus {
  animation: .2s mibounce;
}

.crepi-wrapper:not(.hidden) img.crepi.pressed {
  transform: translateY(10px);
}

.social-media-icons {
  width: 100%;
  max-width: 1920px;
  justify-content: space-around;
  padding-top: 25px;
  display: flex;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.social-media-icons .social-media-icon {
  max-width: 11vw;
  z-index: 1;
  opacity: .5;
  width: 100px;
  transition: opacity .25s ease-in-out;
  position: relative;
}

.social-media-icons .social-media-icon:hover {
  opacity: 1;
}

.social-media-icons .social-media-icon.hidden {
  opacity: 0;
}

.social-media-icons .social-media-icon.animate__heartBeat {
  z-index: 15;
  opacity: .8;
}

@media screen and (orientation: landscape) {
  .social-media-icons {
    height: 100%;
    max-width: 100px;
    flex-direction: column;
    justify-content: space-around;
    display: flex;
    position: absolute;
    top: 0;
    left: 15px;
    transform: translate(0);
  }

  .social-media-icons .social-media-icon {
    max-height: 10vh;
    width: auto;
  }
}

@media screen and (orientation: landscape) and (max-height: 650px) {
  .crepi-wrapper {
    width: 80%;
    height: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    display: flex;
  }

  .crepi-wrapper .bubble-wrapper {
    flex: 1;
    align-self: center;
  }

  .crepi-wrapper .bubble-wrapper .bubble:after {
    animation: 5s ease-in-out infinite float3;
    left: auto;
    right: 0;
  }

  .crepi-wrapper .crepi-img-wrapper {
    flex: 1;
    align-self: flex-end;
    position: relative;
  }
}

.v-btn {
  color: #fff;
  opacity: .5;
  text-decoration: none;
  transition: opacity .3s ease-in-out;
  position: fixed;
  top: 0;
}

.v-btn:hover {
  opacity: 1;
}

.v-btn.prev-v-btn {
  background: linear-gradient(135deg, #333 50%, #0000 50%);
  padding: .5rem 3rem 3rem .5rem;
  left: 0;
}

.v-btn.next-v-btn {
  background: linear-gradient(45deg, #0000 50%, #333 50%);
  padding: .5rem .5rem 3rem 3rem;
  right: 0;
}

/*# sourceMappingURL=index.322a355f.css.map */
