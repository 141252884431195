* {
  box-sizing: border-box;
}

body {
  overflow: hidden;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  max-height: -webkit-fill-available;
  padding: 0;
  margin: 0;
  // background-color: #8f9fac;
  background-color: #644a77;
  background-image: url("img/castle_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  backdrop-filter: blur(25px);
  // background-color: #372656;
}

@keyframes mibounce {
  0%,
  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  // 40% {
  // 	-webkit-transform: translateY(-30px);
  // 	-ms-transform:     translateY(-30px);
  // 	transform:         translateY(-30px)
  // }
  40% {
    -webkit-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

@keyframes mifloat {
  0% {
    transform: translateY(50%);
  }
  25% {
    transform: translateY(55%);
  }
  // 75% {
  //   transform: translateY(55%);
  // }
  100% {
    transform: translateY(50%);
  }
}

.wrapper {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
}

.crepi-wrapper {
  bottom: 0;
  left: 50%;
  max-width: 100%;
  transform: translateX(-50%);
  display: inline-block;
  position: absolute;
  text-align: center;
  z-index: 10;

  .bubble {
    transition: opacity 2s ease-in-out;
    opacity: 1;
    z-index: 10;
    .messages.hidden,
    .clicking-game.hidden {
      display: none;
    }
    .close-btn {
      cursor: pointer;
    }
  }

  img.crepi {
    cursor: pointer;
    position: relative;
    max-width: 70vw;
    max-height: 50vh;
    transition: translateY 2s ease-in-out;
    filter: drop-shadow(15px 15px 15px #444);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
    z-index: 5;
  }
  &.hidden {
    .bubble {
      opacity: 0;
    }
    img.crepi {
      animation: mifloat 6s ease-in-out infinite;
    }
  }
  &:not(.hidden) {
    img.crepi {
      &:hover,
      &:focus {
        animation: mibounce 200ms;
      }
      &.pressed {
        transform: translateY(10px);
      }
    }
  }

  // img.crepi {
  //   // filter: drop-shadow(5px 5px 5px #222222);
  //   cursor: pointer;
  //   &.hidden {
  //     animation: mifloat 6s ease-in-out infinite;
  //   }
  //   &:not(.hidden) {
  //     &:hover, &:focus {
  //       animation: mibounce 200ms;
  //     }
  //   }
  //   &:focus {
  //     animation: none;
  //   }
  // }
}
.social-media-icons {
  // make flexbox
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 1920px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  padding-top: 25px;
  .social-media-icon {
    max-width: 11vw;
    position: relative;
    z-index: 1;
    opacity: 0.5;
    width: 100px;
    transition: opacity 0.25s ease-in-out;
    &:hover {
      opacity: 1;
    }
    &.hidden {
      opacity: 0;
    }
    &.animate__heartBeat {
      z-index: 15;
      opacity: 0.8;
    }
  }
}

@media screen and (orientation: landscape) {
  .social-media-icons {
    // make flexbox
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    max-width: 100px;
    position: absolute;
    transform: translate(0, 0);
    left: 15px;
    top: 0;
    .social-media-icon {
      max-height: 10vh;
      width: auto;
    }
  }
  @media screen and (max-height: 650px) {
    .crepi-wrapper {
      width: 80%;
      height: 100%;
      display: flex;
      justify-content: space-evenly;
      flex-direction: row;
      // align-items: flex-end;
      // .bubble {
      //   flex: 1;
      //   align-self: center;
      // }
      // .bubble {
      //   right: 50%;
      //   top: 50%;
      // }
      // img.crepi {
      //   // height: 100%;
      //   // width: 50px;
      //   // max-height: 50vh;

      //   left: 50%;
      // }
      .bubble-wrapper {
        flex: 1;
        align-self: center;
        .bubble:after {
          right: 0;
          left: auto;
          animation: float3 5s ease-in-out infinite;
        }
      }
      .crepi-img-wrapper {
        position: relative;
        flex: 1;
        align-self: flex-end;
      }
    }
  }
}

.v-btn {
  color: #fff;
  position: fixed;
  top: 0;
  text-decoration: none;
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 1;
  }

  &.prev-v-btn {
    background: linear-gradient(135deg, #333 50%, rgba(0, 0, 0, 0) 50%);
    padding: 0.5rem 3rem 3rem 0.5rem;
    left: 0;
  }

  &.next-v-btn {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0) 50%, #333 50%);
    padding: 0.5rem 0.5rem 3rem 3rem;
    right: 0;
  }
}
